import { Routes } from '@angular/router';
import { ModuleType } from './core/enums/module-type.enum';
import { PageType } from './core/enums/page-type.enum';
import {
  BrandUrlMatcher,
  C0UrlMatcher,
  FamilyUrlMatcher,
  ListingUrlMatcher,
  PdpUrlMatcher,
} from './core/url-matcher';
export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.HOMEPAGE,
      module: ModuleType.CATALOG,
    },
  },
  { path: 'invite', redirectTo: '/', pathMatch: 'full' },
  { path: 'special_offer', redirectTo: '/offers', pathMatch: 'full' },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.PRIVACY_POLICY,
      module: ModuleType.CATALOG,
    },
  },
  {
    path: 'terms-and-conditions',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.TERMS_AND_CONDITIONS,
      module: ModuleType.CATALOG,
    },
  },
  {
    path: 'cancellations-and-returns',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.CANCELLATIONS_AND_RETURNS,
      module: ModuleType.CATALOG,
    },
  },
  {
    path: 'about-us',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.ABOUT_US,
      module: ModuleType.CATALOG,
    },
  },
  {
    path: 'all-brands',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.ALL_BRANDS,
      module: ModuleType.CATALOG,
    },
  },
  {
    path: 'faq',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.FAQ_PAGE,
      module: ModuleType.CATALOG,
    },
  },
  {
    path: 'bulk-discount',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.BULK_DISCOUNT,
      module: ModuleType.CATALOG,
    },
  },
  {
    path: 'report-infringement',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.REPORT_INFRINGEMENT,
      module: ModuleType.CATALOG,
    },
  },
  {
    path: 'gst',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.GST_BENEFITS,
      module: ModuleType.CATALOG,
    },
  },
  {
    path: 'contact-us',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.CONTACT_US,
      module: ModuleType.CATALOG,
    },
  },
  {
    path: 'catalogue',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.CATALOGUE, 
      module: ModuleType.CATALOG,
    },
  },
  {
    path: 'ib-credit',
    loadComponent: () =>
      import('./pages/render-engine/render-engine.component').then(
        (m) => m.RenderEngineComponent,
      ),
    data: {
      pageType: PageType.IB_CREDIT,
      module: ModuleType.USER,
    },
  },
  { path: 'ib_credit', redirectTo: '/ib-credit', pathMatch: 'full' },
  {
    path: 'order',
    loadChildren: () =>
      import('./routes/order/order.routes').then((mod) => mod.ORDER_ROUTES),
    data: {
      pageType: 'CART',
      module: ModuleType.CHECKOUT,
    },
  },
  {
    path: 'user',
    loadChildren: () => import('./routes/index').then((mod) => mod.USER_ROUTES),
    data: {
      pageType: PageType.USER,
      module: ModuleType.USER,
    },
  },
  /** do not put any othter route below custom url mathers. */
  {
    path: 'categories',
    loadChildren: () =>
      import('./routes/index').then((mod) => mod.CATEGORY_ROUTE),
    data: {
      pageType: PageType.ALL_CATEGORIES,
      module: ModuleType.CATALOG,
    },
  },
  {
    matcher: BrandUrlMatcher,
    loadChildren: () =>
      import('./routes/index').then((mod) => mod.CATALOG_ROUTES),
    data: {
      pageType: PageType.BRAND_STORE,
      module: ModuleType.CATALOG,
    },
  },
  {
    matcher: C0UrlMatcher,
    loadChildren: () =>
      import('./routes/index').then((mod) => mod.CATALOG_ROUTES),
    data: {
      pageType: 'CATEGORY0_PAGE',
      module: ModuleType.CATALOG,
    },
  },
  {
    matcher: FamilyUrlMatcher,
    loadChildren: () =>
      import('./routes/index').then((mod) => mod.CATALOG_ROUTES),
    data: {
      pageType: PageType.FAMILY_PAGE,
      module: ModuleType.CATALOG,
    },
  },
  // {
  //   matcher: BrandUrlMatcher,
  //   loadChildren: () =>
  //     import('./brand-page/brand-page.module').then((m) => m.BrandPageModule),
  // },
  {
    matcher: ListingUrlMatcher,
    loadChildren: () =>
      import('./routes/index').then((mod) => mod.CATALOG_ROUTES),
    data: {
      pageType: 'LISTING_PAGE',
      module: ModuleType.CATALOG,
    },
  },
  {
    matcher: PdpUrlMatcher,
    loadChildren: () =>
      import('./routes/index').then((mod) => mod.CATALOG_ROUTES),
    data: {
      pageType: 'PRODUCT_PAGE',
      module: ModuleType.CATALOG,
    },
  },
  {
    path: '**',
    loadComponent: () =>
      import('./widgets/page-not-found/page-not-found.component').then(
        (m) => m.PageNotFoundComponent,
      ),
  },
  ,
];
